//import $ from 'jquery';
import ClipboardJS from 'clipboard';

function copyLink() {
    const clickelement = document.querySelectorAll('.copytoclipboard');

    if (!clickelement) {
        return false;
    }

    new ClipboardJS('.copytoclipboard');

    clickelement.forEach((el) => {
        el.onclick = () => {
            // clipboard.on('success', function (e) {
            //     console.info('Action:', e.action);
            //     console.info('Text:', e.text);
            //     console.info('Trigger:', e.trigger);
            // });

            // clipboard.on('error', function (e) {
            //     console.info('Action:', e.action);
            //     console.info('Text:', e.text);
            //     console.info('Trigger:', e.trigger);
            // });

            // Zeige Status nach Klick
            let copyinfo = document.getElementById('copyinfo');

            if (copyinfo.style.display === 'none') {
                copyinfo.style.display = 'block';
            }
        };
    });
}

copyLink();
